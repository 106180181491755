.experience {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.experience h2 {
    text-align: center;
}

@media only screen and (max-width: 48em) {
  .experience {
      display: block;
      width: 100%;
  }
}
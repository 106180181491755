.project {
  max-width: 1024px;
  width: 100%;
  display: flex;
  margin-top: 50px;
}

.project div {
    flex: 1;
}

.project-title div {
    width: 400px;
    display: flex;
    justify-content: space-between;
}

.project-icons {
    padding: 0;
    list-style: none;
    display: flex;
}

.project-link {
    font-size: 0;
}
  
.visually-hidden { /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

@media only screen and (max-width: 48em) {
  .project {
    display: block;
  }

  .project div {
    margin: 0 auto;
  }

  .project-title {
    display: block;
  }

  .job-img {
    display: flex;
    justify-content: center;
  }

  .job-img img {
    height: 100%;
  }

  .project-content {
    display: block;
  }

  .project-description {
    margin: 0 auto;
  }

  .project-description p {
    width: 90%;
    margin: 20px auto;
  }
}
.job {
  margin-top: 50px;
  display: flex;
}

.job div {
  flex: 1;
}

.job-title {
  margin-right: 25px;
}

.job-title img {
  width: 400px;
}

.left-column {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-column a {
  cursor: pointer;
}

.job-link {
  font-size: 0;
}

.technologies {
  display: flex;
}

@media only screen and (max-width: 48em) {
  .job {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  .job-title {
    margin: 0 auto;
  }

  .job-img {
    margin: 0 auto;
  }

  .job-content {
    display: flex;
    flex-direction: column;
  }

  .job-text {
    margin: 0 auto;
    width: 90%;
  }

  .left-column {
    width: 90%;
    margin: 0 auto;
  }
}
.contact {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-align: center;
}

.contact-section {
  display: flex;
  justify-content: center;
}

.contact-links a {
  padding: 0px 50px;
}

.contact-links a i {
  font-size: 3rem;
}

.footer {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 425px) {
  .contact {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-links a {
    padding: 0 10px;
  }
  .contact-links a i {
    font-size: 2rem;
  }
}
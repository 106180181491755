* {
  box-sizing: border-box;
  max-width: 100%;
}

#about {
  width: 100%;
}


html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  display: block;
  font-family: 'Roboto Mono', monospace;
  color: #033649;
}

h1 {
  font-size: 38px;
  margin-bottom: 0;
}

h2 {
  font-size: 30px;
  margin-bottom: 0;
}

h3 {
  font-size: 25px;
  margin-bottom: 0;
}

h1, h2, h3 {
  font-family: 'Fira Code', monospace;
  font-weight: strong;
}

body a {
  color: #DCB85A;
  padding: 0 7.5px 0 7.5px;
}

body a:hover {
  color: #046C46;
}

.anchor {
  display: block;
  height: 30px; /*same height as header*/
  margin-top: -30px; /*same height as header*/
  visibility: hidden;
}

.contact-links {
  text-align: center;
}

section {
  padding: 25px 20px 0 20px;
  max-width: 1024px;
}

@media only screen and (max-width: 425px) {
  * {
    max-width: 100%;
  }

  body {
    position: absolute;
  }
}
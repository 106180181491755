.header {
  padding-top: 75px;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.name-header {
  display: flex;
}

.name-header div {
  flex: 1;
}

.name-titles {
  text-align: center;
  margin: 0 35px 0 0;
}

.intro-icons {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}

.intro-icons li {
  margin: 20px 15px;  
}

@media only screen and (max-width: 48em) {
  .header {
    height: 100%;
  }

  .intro-paragraph {
    text-align: center;
    text-shadow: none;
    color: #033649;
  }

  .name-titles {
    margin-top: 45px;
  }

  .name-titles h1, .name-titles h2, .name-titles h3 {
    margin: 10px auto;
  }

  .name-header {
    margin: -75px auto 0 auto;
    display: block;
    width: 90%;
  }

  .name-header div {
    width: 100%;
  }

  .name-header h1 {
    font-size: 24px;
  }

  .name-header h2, .name-header h3 {
    font-size: 20px;
  }

  .intro-paragraph h3 {
    margin-top: 0;
  }
}
.projects {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.projects h2 {
  text-align: center;
  margin-bottom: 0;
}

@media only screen and (max-width: 425px) {
  .projects {
    width: 90%;
  }
}
.navigation-wrapper {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0;
}

.navigation {
  font-weight: 800;
  margin: 0 auto;
  max-width: 1024px;
}

.navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.navigation li a {
  display: block;
  padding: 20px 15px;
  text-decoration: none;
  cursor: pointer;
}

.navigation .logo {
  cursor: pointer;
  display: block;
  float: left;
  font-size: 1rem;
  padding: 10px 15px;
  text-decoration: none;
}

/* menu */

.navigation .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.navigation .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 18px 18px;
  position: relative;
  user-select: none;
}

.navigation .menu-icon .navicon {
  background: #033649;
  display: block;
  height: .5px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.navigation .menu-icon .navicon:before,
.navigation .menu-icon .navicon:after {
  background: #033649;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.navigation .menu-icon .navicon:before {
  top: 5px;
}

.navigation .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.navigation .menu-btn {
  display: none;
}

.navigation .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.navigation .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.navigation .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.navigation .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.navigation .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.navigation .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .navigation li {
    float: left;
  }
  .navigation li a {
    padding: 10px 10px;
  }
  .navigation .logo {
    font-size: 1.5rem;
  }
  .navigation .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .navigation .menu-icon {
    display: none;
  }
}
